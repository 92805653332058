<template>
    <v-row no-gutters @click="onClickOutside" style="height: 100%;">
        <v-col cols="12">
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10">
                    <v-card class="pa-0">
                        <v-toolbar short flat :style="cardTitleBarStyle">
                            <v-toolbar-title :style="cardTitleBarTextStyle">Search domains</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text v-if="Array.isArray(domainList) && domainList.length === 0">
                            No domains.
                        </v-card-text>
                        <v-simple-table dense>
                            <template #default>
                                <thead>
                                    <tr>
                                        <th>Domain</th>
                                        <th>Account</th>
                                        <th>Nameserver Group</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(domain, idx) in domainList" :key="idx" @click.stop="selectDomainIdx(idx)" class="clickable-row" :class="{ highlight: idx === selectedDomainIdx }">
                                        <td>{{ domain.name }}</td>
                                        <td>
                                            <template v-if="domain.account">
                                                {{ domain.account.name }}
                                            </template>
                                            <template v-else>
                                                {{ domain.account_id }}
                                            </template>
                                        </td>
                                        <td>
                                            <template v-if="domain.nameserver_group">
                                                {{ domain.nameserver_group.label }}
                                            </template>
                                            <template v-else-if="domain.nameserver_group_id">
                                                {{ domain.nameserver_group_id }}
                                            </template>
                                            <template v-else>
                                                <v-btn x-small color="amber darken-2 white--text" @click="assignDefaultNameserverGroupToDomain(domain)">Fix</v-btn>
                                            </template>
                                        </td>
                                        <td style="text-align: right;">
                                            <v-menu offset-y left open-on-click open-on-hover close-delay="100">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn icon v-on="on">
                                                        <font-awesome-icon :icon="['far', 'ellipsis-v']" fixed-width/>
                                                    </v-btn>
                                                </template>
                                                <v-list class="ma-0 pa-0">
                                                    <v-list-item-group>
                                                    <v-list-item @click="checkDomainNameservers(domain)">
                                                        <v-list-item-icon>
                                                            <font-awesome-icon :icon="['fas', 'wrench']" :color="primaryColor" fixed-width/>
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title>Check nameservers</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    </v-list-item-group>
                                                </v-list>
                                            </v-menu>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style>
tr.clickable-row:hover {
    cursor: pointer;
}
tr.highlight {
    background-color: #FFF9C4; /* yellow lighten-4 */
}
tr.highlight:hover {
    background-color: #FFF59D !important; /* yellow lighten-3 */
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    data: () => ({
        domainList: null,
        selectedDomainIdx: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            cardTitleBarStyle: 'cardTitleBarStyle',
            cardTitleBarTextStyle: 'cardTitleBarTextStyle',
        }),
    },
    watch: {
        focus() {
            this.loadDomainList();
        },
    },
    methods: {
        init() {
            this.loadDomainList();
        },
        async loadDomainList() {
            try {
                this.$store.commit('loading', { loadDomainList: true });
                const response = await this.$client.main().system.getDomainList({ include_account: true, include_nameserver_group: true });
                if (response?.list) {
                    this.domainList = response.list;
                }
            } catch (err) {
                console.error('failed to load domain list', err);
            } finally {
                this.$store.commit('loading', { loadDomainList: false });
                this.isViewReady = true;
            }
        },
        selectDomain(name) {
            // this.$router.push({ name: 'system-edit-nameserver-group', query: { id } });
            console.log(`selectDomain(${name})`);
        },
        selectDomainIdx(idx) {
            this.selectedDomainIdx = idx;
        },
        onClickOutside() {
            this.selectedDomainIdx = null;
        },
        async assignDefaultNameserverGroupToDomain(domain) {
            try {
                this.$store.commit('loading', { assignDefaultNameserverGroupToDomain: true });
                const response = await this.$client.main().system.checkDomain({ name: domain.name, account_id: domain.account_id }, { item: 'nameserver_group', fix: true });
                if (response?.status?.isEdited) {
                    this.loadDomainList();
                }
            } catch (err) {
                console.error('failed to fix nameserver group id', err);
            } finally {
                this.$store.commit('loading', { assignDefaultNameserverGroupToDomain: false });
            }
        },
        async checkDomainNameservers(domain) {
            try {
                this.$store.commit('loading', { checkDomainNameservers: true });
                const response = await this.$client.main().system.checkDomain({ name: domain.name, account_id: domain.account_id }, { item: 'ns_record', fix: true });
                if (response?.status?.isEdited) {
                    this.loadDomainList();
                }
            } catch (err) {
                console.error('failed to fix nameserver group id', err);
            } finally {
                this.$store.commit('loading', { checkDomainNameservers: false });
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>
